<template>
  <section>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="note_main">
        <section class="note_main_content">
          <p class="note_main_title">
            {{ note.title }}
          </p>
          <p class="note_main_subtitle_0">
            {{ note.date }}
          </p>
          <p class="note_main_subtitle">
            CL: {{ note.class }}
          </p>
          <p class="note_main_txt">
            {{ note.body }}
          </p>
          <div class="imgContainer pt-5">
            <div
              v-for="img in note.imgUrls"
              :key="img"
            >
              <VImg
                :src="img"
                :lazy-src="require('../../assets/icons/loading.png')"
                alt="Image"
                class="imgContent"
                aspect-ratio="1"
                @click="openImg(img)"
              >
                <template v-slot:placeholder>
                  <VLayout
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <VProgressCircular
                      indeterminate
                      color="grey lighten-3"
                    />
                  </VLayout>
                </template>
              </VImg>
            </div>
          </div>
        </section>
      </section>
    </section>
    <VDialog
      v-model="isLoading"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_fs elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="white"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_fs elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_fs elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    isLoading: true,
    loadStatus: '',
    retry: 0,
    noResult: false,

    note: {
      title: '',
      body: '',
      class: '',
      date: '',
      imgUrls: [],
    },
  }),
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      const { id } = this.$route.params;
      await axios.get(`${api}/note/${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.note = res.data.data;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
      this.isLoading = false;
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    openImg(img) {
      window.open(img);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Note/view';
</style>
